@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  opacity: 1;
  animation: fadeIn 1s forwards;
}

.fade-in-part {
  opacity: 1;
  animation: fadeIn 4s forwards;
}

.fade-out {
  opacity: 0;
  animation: fadeOut 1s forwards;
}



.nav-icon, .logo-svg, .responsive-image, .smaller-project {
  animation: fadeIn 2s ease-out forwards;
}

.text-image {
  animation: fadeIn 2s ease-out 1s forwards; /* Starts after 3 seconds, lasts for 5 seconds */
}



