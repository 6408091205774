
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(201, 76, 76, 0);/* Dark blue background */
    padding: 1rem;
     /* Soft shadow for depth */
    margin: 20px 0px 0px 0px;
  
  }
  
  .logo-svg {
    height: 30px; /* Adjust based on your logo */
     /* Pushes navigation links to the right */
     margin-left: 20px;
     margin-right: 400px;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    
  }
  
  
  .nav-icon {
    width: 70px; /* Uniform width for all icons */
    height: 23px; /* Uniform height for all icons */
    margin: 0 20px; /* Spacing between icons */
    transition: transform 0.3s ease; /* Smooth scaling transition */
  }

  
  .nav-icon:hover {
    transform: scale(1.1); /* Slightly enlarge icons on hover */
    cursor: pointer;
  }
  
  .smaller-icon {
    width: 59px; /* Smaller width for specific icons */
    height: 23px; /* Smaller height for specific icons */
    margin: 0 20px; /* Spacing between icons */
    transition: transform 0.3s ease; /* Smooth scaling transition */
  }
  
  .smaller-about {
    width: 53px; /* Smaller width for specific icons */
    height: 23px; /* Smaller height for specific icons */
    margin: 0 20px; /* Spacing between icons */
    transition: transform 0.3s ease; /* Smooth scaling transition */
  }
  .smaller-project {
    width: 80px; /* Uniform width for all icons */
    height: 23px; /* Uniform height for all icons */
    margin: 0 20px; /* Spacing between icons */
    transition: transform 0.3s ease; /* Smooth scaling transition */
  }

  
  /* Responsive layout for smaller screens */
@media (max-width: 1024px) {
    nav{
      margin: 0px 0px 0px 0px;
  
    }
    .nav-links {
      display: none; /* Optionally hide text links on smaller screens */
    }
  
    .logo-svg {
      margin: 0 auto; /* Center logo on smaller screens */
    }
}