/* Footer.css */
.footer {
    width: 90%;
    background-color: rgba(217, 217, 217, 0.05); /* Adjusted transparency */
    color: white;
    padding: 20px 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    font-family: 'IBM Plex Mono', monospace;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    margin-bottom: 20px;
    margin-top: 240px;
    position: relative;
    flex-direction: row-reverse;
    padding-bottom: 60px; /* Needed for absolute positioning of copyright */
  }

.footer-right-sections {
    display: flex;
    flex-direction: row; /* Arrange right sections in a row */
    align-items: flex-start; /* Align items at the start of the flex container */
    gap: 40px; /* Adjust as needed */
  }
  
/* Correctly position copyright section at the bottom left */
.footer-section.copyright {
    position: absolute; /* Positions copyright at the bottom left */
    bottom: 20px;
    left: 3%;
    font-size: 13px;
    font-weight: bold;
    color: white;
  }
  
  .footer-section.support{
    margin-bottom: 40px;
  }
  .footer-section {
    display: flex;
    flex-direction: column;
    margin: 1px;
    font-size: 15px;
    /* Default font size for other texts */
  }


  
  .footer-section p {
    font-weight: bold; /* Titles are bold */
    margin-bottom: 20px;
  }

  .footer-section a, .footerlink, .social-link {
    font-weight: normal;
    margin-bottom: 6px;
    color: white;
    text-decoration: none;
  }
  
  .footer-link:hover, .social-link:hover {
    text-decoration: none; /* Keeps underline removed on hover */
  }
  
  .footer a, .footer a:hover, .footer a:visited {
    color: white; /* This forces the color to white */
    text-decoration: none; /* This removes the underline */
  }

@media (max-width: 1024px) {

    .footer-right-sections {
        flex-direction: column; /* Stack the sections vertically on smaller screens */
        align-items: center; /* Center-align the sections for a cleaner look on mobile */
        }


    .footer {
        width: 80%;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        padding-bottom: 60px; 
    }

    .footer-section {
        align-items: center;
    }

}
