/* App.css */



body::-webkit-scrollbar {
  display: none;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  /* Smooth transition between similar hues for a more professional look */
  background: linear-gradient(-45deg, #0b0924, #282458, #19254a, #282458, #0b0924);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}



body, html {
  width: 100%;
  overflow-x: hidden; /* Only if you're sure you want to disable horizontal scrolling */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}



.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  width: 100%;
}

.main-content {
  display: flex;
  flex-direction: row;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content horizontally */
  margin-top: 1rem;
  width: 100%; /* Make main content full width */
}

.main2-container {
  position: relative;
  width: 60%; /* Match the width of main2-image for alignment */
  margin-top: 10rem;
  align-self: center; /* Center the container if the parent is flex */
}

.main2-container-foot {
  position: relative;
  width: 60%; /* Match the width of main2-image for alignment */
  margin-top: 15rem;
  align-self: center; /* Center the container if the parent is flex */
}

.projects-icon {
  position: absolute;
  top: -60px; /* Adjust as necessary to position correctly */
  left: 100px;
  width: 120px; /* Adjust based on your needs */
  height: 40px; /* Adjust based on your needs */
  transform: translateX(-100%); /* Adjust if necessary for positioning */
}



.main2-image {
  width: 100%; /* Use 100% of the container's width for alignment */
  height: auto; /* Maintain aspect ratio */
}

.main3-image {
  width: 100%; /* Use 100% of the container's width for alignment */
  height: auto; /* Maintain aspect ratio */
  margin-left: 40px;
}

.main4-image {
  width: 100%; /* Use 100% of the container's width for alignment */
  height: auto; /* Maintain aspect ratio */
}


.responsive-image {
  max-width: 40%;
  height: auto;


}

.text-image {
  width: 90%; /* Adjust width as necessary */
  margin: 0 auto; /* Center the image */
  opacity: 0; 
}



.scroll-to-top-icon {
  position: fixed;
  right: 40px;
  bottom: -100px; /* Start outside of view */
  width: 50px; /* Adjust as needed */
  height: auto;
  cursor: pointer;
  transition: bottom 0.3s ease-out; /* Smooth transition for the 'bottom' property */
  opacity: 0; /* Start as invisible */
  z-index: 20;
}

.scroll-to-top-icon.visible {
  bottom: 20px; /* Move into view */
  opacity: 1; /* Make it visible */
}


.scroll-to-top-icon:not(.visible) {
  animation: fadeOut 0.3s ease-out forwards;
}


/* Responsive layout for smaller screens */
@media (max-width: 1024px) {

  .projects-icon{
    position: absolute;
    top: -60px; /* Adjust as necessary to position correctly */
    left: 80px;
    width: 80px; /* Adjust based on your needs */
    height: 40px; /* Adjust based on your needs */
    transform: translateX(-100%); /* Adjust if necessary for positioning */
  }

  .footer-image {
    max-width: 100%; /* Make sure the image is responsive */
    height: auto; /* Maintain aspect ratio */
    margin-top: 70px;
  }
  

  .main2-container {
    width: 90%;
  }

  .main2-container-foot{
    width: 90%;
  }

  .main-content {
    flex-direction: column;
    margin-top: 1rem; /* Add space on top of the main content */
  }

  .responsive-image{
    max-width: 40%; /* Give some padding on the sides */
    margin-bottom: 0rem; /* Add space between image  */
    margin-right: 0rem;
  }

  .text-image-container {
    display: flex;
    justify-content: center; /* Center children horizontally */
    width: 90%; /* Ensure full width for proper centering */
  }

  .text-image {
    width: 80%; /* Adjust width as necessary */
    margin: 0 auto; /* Maintain auto margins for horizontal centering */
    opacity: 1; /* Ensure it's visible if previously hidden */
    animation: none; /* Optional: remove animation on small screens for direct visibility */
  }
  .main3-image {
    width: 100%; /* Use 100% of the container's width for alignment */
    height: auto; /* Maintain aspect ratio */
    margin-left: 20px;
  }

  .responsive-image, .main2-image, .main3-image, .main4-image {
    transition: transform 0.5s ease;
    cursor: zoom-in;
  }
  
  .responsive-image:hover, .main2-image:hover, .main3-image:hover, .main4-image:hover {
    transform: scale(1.1); /* Adjust scale value as needed */
  }

}
